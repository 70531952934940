<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo">
                <div
                    v-if="getterExamStatus === 'in_progress'"
                    class="exam_details"
                >
                    <div class="exam_details_item">
                        <p class="exam_details_title">
                            Questions
                        </p>
                        <p class="exam_details_subtitle">
                            <b>{{ currentIndex }}</b>/{{ getterQuestionsTotal }}
                            <span>(remainder/total)</span>
                        </p>
                    </div>
                    <div class="exam_details_item exam_details_item--countdown">
                        <p class="exam_details_title">
                            Countdown
                        </p>
                        <p class="exam_details_subtitle">
                            <b>{{ getterCountdown }}</b>
                        </p>
                    </div>
                </div>
            </header-content>
            <div class="exam-wrapper exam-wrapper--p0">
                <Exam />
            </div>
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import HeaderContent from '@/components/HeaderContent';

import Exam from '@/components/wizard/WizardExam/Exam';

import { mapGetters } from 'vuex';

require('moment-countdown');

export default {
    components: {
        MainLayout,
        HeaderContent,
        Exam
    },
    metaInfo: {
        title: 'Online English Exam'
    },
    data() {
        return {
            countdown: null
        }
    },
    computed: {
        ...mapGetters('exam',
            [
                'getterExamStatus',
                'getterQuestionsTotal',
                'getterQuestionsLeft',
                'getterCountdown'
            ]),
        currentIndex() {
            return this.getterQuestionsTotal - this.getterQuestionsLeft + 1
        },
        headerInfo() {
            return {
                title: 'Online English Exam',
                className: 'header-content__documents',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    },
                    {
                        title: 'Documents',
                        url: '/cp/documents'
                    }
                ]
            }
        }
    }
}
</script>

<style lang="scss">
    .exam_details{
        display: flex;
        color: #fff;
        &_item {
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
            &--countdown{
                width: 220px;
            }
        }
        &_title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        &_subtitle{
            font-size: 16px;
            span {
                font-size: 12px;
            }
        }
    }
    .exam-wrapper--p0{
        .wizard__title{
            padding: 0;
        }
    }

</style>
